<template lang="">
  <div class="order-item">
    <div class="item">
      <span class="label">客户姓名：</span>
      <span class="value">{{item.complaintUserName | dash}} - {{ item.complaintUserPhone | encryptPhone}}</span>
    </div>
    <div class="item">
      <span class="label">客户诉求：</span>
      <span class="value">{{item.complaintReason | dash}}</span>
    </div>
    <div class="item">
      <span class="label">内容简述：</span>
      <span class="value">{{item.complaintContent | dash}}</span>
    </div>
    <div class="item">
      <span class="label">跟进措施：</span>
      <span class="value">{{item.followMeasures | dash}}</span>
    </div>
    <div class="item">
      <span class="label">备注：</span>
      <span class="value"
        >{{item.remark | dash}}</span
      >
    </div>
    <div class="item">
      <span class="label">接听人：</span>
      <span class="value">{{item.answeringStaff | dash}}</span>
    </div>
    <div class="item">
      <span class="label">记录时间：</span>
      <span class="value">{{item.createTime | dash}}</span>
    </div>
    <div class="item">
      <span class="label">处理时间：</span>
      <span class="value">{{item.processingTime | dash}}</span>
    </div>
    <ProcessBadge
      class="business-badge"
      :text="item.workStatusName"
      color="#2496FF"
      :textSize="60"
      :rotate="15"
    />
  </div>
</template>
<script>
import ProcessBadge from "@/components/ProcessBadge.vue";
export default {
  components: { ProcessBadge },
  props: {
    item: {
      type: Object,
      default: () => {},
    }
  },
};
</script>
<style lang="less" scoped>
.order-item {
  position: relative;
  .item {
    display: flex;
    overflow: hidden;
    line-height: 24px;
    span {
      display: block;
    }
    .label {
      flex: 0 0 80px;
      color: #666;
    }
    .value {
      flex: 1;
      // text-overflow: ellipsis;
      // overflow: hidden;
      // white-space: nowrap;
      color: #333;
    }
  }
  .business-badge{
    position: absolute;
    right: 0;
    top: -50px;
  }
  .van-collapse-item__wrapper{
    overflow: auto;
  }
}
</style>
