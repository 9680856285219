<template>
  <div class="custom">
    <Sticky>
      <NavBar title="客户信息" left-arrow @click-left="onClickLeft" />
      <Tabs v-model="activeName">
        <Tab title="基本信息" name="basic"></Tab>
        <Tab title="客户工单" name="order"></Tab>
      </Tabs>
    </Sticky>
    <div class="content">
      <Collapse v-model="basicActiveNames" v-show="activeName == 'basic'">
        <Basic :projectCode="projectCode"></Basic>
      </Collapse>
      <Collapse v-model="orderActiveNames" v-show="activeName == 'order'">
        <CollapseItem
          :title="
            item.complaintTypeDisplayName + '-' + item.complaintLevelDisplayName
          "
          v-for="(item, index) in orderList"
          :key="index"
          :name="index"
          :is-link="false"
          disabled
          ><OrderItem :item="item"></OrderItem
        ></CollapseItem>
        <Empty v-if="!orderList.length" description="暂无数据"></Empty>
      </Collapse>
    </div>
    <div class="bottom-btn">
      <div class="btns">
        <Button
          type="primary"
          hairline
          @click="$router.push({ path: '/trial?projectCode=' + projectCode })"
          >结清试算</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/index.js";
import {
  Tab,
  Tabs,
  NavBar,
  Collapse,
  CollapseItem,
  Button,
  Sticky,
  Empty,
} from "vant";
import OrderItem from "@/components/OrderItem";
import Basic from "./components/Basic";
export default {
  name: "Custom",
  components: {
    Tab,
    Tabs,
    NavBar,
    Collapse,
    CollapseItem,
    OrderItem,
    Basic,
    Button,
    Sticky,
    Empty,
  },
  created() {
    this.projectCode = this.$route.query.projectCode;
    if (this.projectCode) {
      this.init();
    }
  },
  data() {
    return {
      activeName: "basic",
      basicActiveNames: ["1", "2", "3", "4", "5"],
      orderList: [],
      projectCode: "",
    };
  },
  computed: {
    orderActiveNames() {
      return this.orderList.map((_, index) => index);
    },
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    init() {
      Api.getWorkOrder(this.projectCode).then((res) => {
        this.orderList = res.data || [];
      });
    },
  },
};
</script>

<style lang="less" scoped>
.custom-top {
  position: sticky;
  top: 0;
  z-index: 111;
}
.content {
  padding: 12px;
  font-size: 14px;
}
.van-collapse-item {
  border-radius: 4px;
  margin-bottom: 12px;
  overflow: hidden;
  box-shadow: 0px 0px 4px 0px rgba(0, 6, 42, 0.08);
  /deep/ .van-cell__title {
    color: #323233 !important;
    font-weight: bold;
  }
}
/deep/.van-collapse-item__wrapper {
  overflow: initial;
}
</style>
